<template>
  <div>
    <div class="card card-custom gutter-b dashboard-farm-controller">
    <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header
            :total-count="measures.length"
        ></t-farmon-table-header>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="rounded-sm" size="sm" @click="showMeasureModal()">{{i18n.measurementInputPage}}</b-button>
        </div>
      </div>
    <!--end::Header-->
    <!--begin::Body-->
      <div class="card-body pt-0">
        <div class="row">
            <div class="form-inline col-xxl-2 col-lg-4 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> 제어기 명 </label>
                <b-form-select v-model="connectFilter" :options="connectFilterList" style="width: 145px" @change="[paging.pageNo=1, linkGen(), getMeasureItems()]"></b-form-select>
            </div>
            <div class="form-inline col-xxl-2 col-lg-3 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.startYmd}} </label>
              <b-form-datepicker
                  v-model="startYmd"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  style="width: 145px"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input="getMeasureItems()"
              ></b-form-datepicker>
            </div>
            <div class="form-inline col-xxl-2 col-lg-3 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.endYmd}} </label>
              <b-form-datepicker
                  v-model="endYmd"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  style="width: 145px"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input="getMeasureItems()"
              ></b-form-datepicker>
            </div>
            <b-list-group horizontal class="mb-3 p-3 mr-auto list-group-horizontal">
              <b-list-group-item
                v-for="(dateRange) in fixedDateRange.dateRanges"
                button
                :key="dateRange.key"
                :active="fixedDateRange.activeDateRange === dateRange.key"
                @click="[onFixedDateRangeChanged(dateRange.key), paging.pageNo=1, linkGen(), getMeasureItems()]"
              >
                {{ dateRange.date }}
              </b-list-group-item>
              <b-list-group-item v-if="!isMobile" button @click="onDownloadExcel()" :title="i18n.excelDownload">
                <i class="far fa-file-excel"></i>
              </b-list-group-item>
            </b-list-group>
            <v-data-table
                :headers="envMeasureFields"
                :items="envMeasureItems"
                class="elevation-1 farm-data-table col-xxl-12 col-lg-12"
                :no-data-text='i18n.noData'
                hide-default-footer
                :items-per-page="50"
            >
              <template v-slot:[`item.fieldC`]="{ item }">
                <div>
                  <span :class="`${item.ecValueCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldC.split('/')[0]}}</span>/
                  <span :class="`${item.ecMeasureCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldC.split('/')[1]}}</span>
                </div>
              </template>
              <template v-slot:[`item.fieldD`]="{ item }">
                <div>
                  <span :class="`${item.phValueCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldD.split('/')[0]}}</span>/
                  <span :class="`${item.phMeasureCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldD.split('/')[1]}}</span>
                </div>
              </template>
              <template v-slot:[`item.fieldF`]="{ item }">
                <div>
                  <span :class="`${item.humidityValueCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldF.split('/')[0]}}</span>/
                  <span :class="`${item.humidityMeasureCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldF.split('/')[1]}}</span>
                </div>
              </template>
              <template v-slot:[`item.fieldG`]="{ item }">
                <div>
                  <span :class="`${item.tempValueCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldG.split('/')[0]}}</span>/
                  <span :class="`${item.tempMeasureCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldG.split('/')[1]}}</span>
                </div>
              </template>
              <template v-slot:[`item.fieldH`]="{ item }">
                <div>
                  <span :class="`${item.co2ValueCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldH.split('/')[0]}}</span>/
                  <span :class="`${item.co2MeasureCheck ? 'text-danger font-weight-bold': ''}`">{{item.fieldH.split('/')[1]}}</span>
                </div>
              </template>
              <template v-slot:[`item.fieldI`]="{ item }">
                <b-button size="sm" variant="outline-dark" @click="showMeasureModal(item)">
                  <span class='icon-xs fas fa-pen mr-2'></span>
                  <span class="font-weight-bold">{{i18n.edit}}</span>
                </b-button>
                <b-button size="sm" variant="dark" class="rounded-sm" @click="deleteMeasure(item)">
                  <span class="fas fa-trash font-size-sm mr-2"></span>
                  <span class="text font-weight-normal">{{i18n.delete}}</span>
                </b-button>
              </template>
            </v-data-table>
        </div>

        <!--begin::pagination-->
        <div v-if="paging.totalCount" class="pagination mt-12">
          <b-pagination-nav
            :number-of-pages="totalPage"
            :link-gen="linkGen"
            use-router
            limit="10"
          >
            <template #first-text><i class="fas fa-angle-double-left"></i></template>
            <template #prev-text><i class="fas fa-angle-left"></i></template>
            <template #next-text><i class="fas fa-angle-right"></i></template>
            <template #last-text><i class="fas fa-angle-double-right"></i></template>
            <template #page="{ page, active }">
              <span v-if="active" class="font-size-sm font-weight-bold text-white">{{ page }}</span>
              <span v-else class="font-size-sm font-weight-bold text-dark">{{ page }}</span>
            </template>
          </b-pagination-nav>
        </div>
        <!--end::pagination-->

    </div>
    <!--end::Body-->
    </div>

    <!-- begin::yieldModal -->
    <b-modal
      v-model="measureModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{ i18n.measure }} {{ isRegister ? i18n.register : i18n.edit}}
          <div><small>(<i class="flaticon2-refresh-arrow bg-secondary p-0 rounded"></i>: {{i18n.getCurrentValue}})</small></div>
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="measureModal = false">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.connectNm}}</span>
          </label>
          <div class="col-sm-4">
            <b-form-select v-model="selectedConnect" :options="connectOptions"></b-form-select>
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.measureDate}}</span>
          </label>
          <div class="col-sm-4">
            <div class="input-group">
              <b-form-datepicker
                v-model="registerDt"
                menu-class=""
                calendar-width="100%"
                :locale="locale"
                :date-format-options="{ year: '2-digit', month: 'numeric', day: 'numeric' }"
                :placeholder="``"
              ></b-form-datepicker>
              <!-- <div class="input-group-prepend input-group-append">
                <div class="input-group-text">.</div>
              </div> -->
              <b-form-select v-model="selectedAmPm" :options="amPmOptions"></b-form-select>
            </div>
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.temperature}}</span>
            <div class="h10">{{i18n.controllerMeasured}}</div>
          </label>
          <div class="col-sm-2">
            <div class="input-group">
              <input v-model="tempValue" type="number" class="farm-form-control form-control" @change="checkValue(tempMeasure, tempValue)">
              <div class="input-group-append">
                <b-button class="input-group-text pr-2" @click="setValue('tempValue')"><i class="flaticon2-refresh-arrow"></i></b-button>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <input v-model="tempMeasure" type="number" class="farm-form-control form-control" @change="checkValue(tempMeasure, tempValue)">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.humidity}}</span>
            <div class="h10">{{i18n.controllerMeasured}}</div>
          </label>
          <div class="col-sm-2">
            <div class="input-group">
              <input v-model="humidityValue" type="number" class="farm-form-control form-control" @change="checkValue(humidityValue, humidityMeasure)">
              <div class="input-group-append">
                <b-button class="input-group-text pr-2" @click="setValue('humidityValue')"><i class="flaticon2-refresh-arrow"></i></b-button>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <input v-model="humidityMeasure" type="number" class="farm-form-control form-control"  @change="checkValue(humidityValue, humidityMeasure)">
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.waterTemperature}} </span>
            <div class="h10">{{i18n.controllerMeasured}}</div>
          </label>
          <div class="col-sm-2">
            <div class="input-group">
              <input v-model="waterTempValue" type="number" class="farm-form-control form-control" @change="checkValue(waterTempValue, waterTempMeasure)">
              <div class="input-group-append">
                <b-button class="input-group-text pr-2" @click="setValue('waterTempValue')"><i class="flaticon2-refresh-arrow"></i></b-button>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <input v-model="waterTempMeasure" type="number" class="farm-form-control form-control" @change="checkValue(waterTempValue, waterTempMeasure)">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.co2}}</span>
            <div class="h10">{{i18n.controllerMeasured}}</div>
          </label>
          <div class="col-sm-2">
            <div class="input-group">
              <input v-model="co2Value" type="number" class="farm-form-control form-control" @change="checkValue(co2Value, co2Measure)">
              <div class="input-group-append">
                <b-button class="input-group-text pr-2" @click="setValue('co2Value')"><i class="flaticon2-refresh-arrow"></i></b-button>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <input v-model="co2Measure" type="number" class="farm-form-control form-control" @change="checkValue(co2Value, co2Measure)">
          </div>
        </div>
        <div class="form-group row mb-2">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.eC}}</span>
            <div class="h10">{{i18n.controllerMeasured}}</div>
          </label>
          <div class="col-sm-2">
            <div class="input-group">
              <input v-model="ecValue" type="number" class="farm-form-control form-control" @change="checkValue(ecValue, ecMeasure)">
              <div class="input-group-append">
                <b-button class="input-group-text pr-2" @click="setValue('ecValue')"><i class="flaticon2-refresh-arrow"></i></b-button>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <input v-model="ecMeasure" type="number" class="farm-form-control form-control" @change="checkValue(ecValue, ecMeasure)">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.pH}}</span>
            <div class="h10">{{i18n.controllerMeasured}}</div>
          </label>
          <div class="col-sm-2">
            <div class="input-group">
              <input v-model="phValue" type="number" class="farm-form-control form-control" @change="checkValue(phValue, phMeasure)">
              <div class="input-group-append">
                <b-button class="input-group-text pr-2" @click="setValue('phValue')"><i class="flaticon2-refresh-arrow"></i></b-button>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <input v-model="phMeasure" type="number" class="farm-form-control form-control" @change="checkValue(phValue, phMeasure)">
          </div>
        </div>
        <div class="form-group row justify-content-end mb-0">
          <b-button variant="secondary" size="lg" class="input-group-text pr-2 align-items-end" @click="setValue('all')"><i class="flaticon2-refresh-arrow"></i><span style="color:black;">전체값 불러오기</span></b-button>
        </div>
      <!-- -->
      </div>
      <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="saveMeasureModal"><span>{{ isRegister ? i18n.register : i18n.edit}}</span></b-button>
            <b-button variant="secondary" size="lg" class="ml-3" @click="measureModal = false"><span class="">{{i18n.close}}</span></b-button>
          </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::yieldModal -->

    <!-- begin::TableForExcelDownload -->
    <table v-show="false" class="table table-vertical-center dtr-inline text-center table-condensed" id="excel_download_table_env">
      <thead class="thead-light">
      <tr role="row">
        <th v-for="item in Excelheader" :key="item" class="sorting" rowspan="1" colspan="1">{{item}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!measures.length">
        <td colspan="15" style="text-align: center">
          <span><br>noData<br><br></span>
        </td>
      </tr>
      <tr :key="idx" v-for="(item, idx) in measures">
        <td>{{ item.measureYmd ? item.measureYmd : ''}}</td>
        <td>{{ item.connectNm ? item.connectNm : ''}}</td>
        <td>{{ item.amPm ? item.amPm : ''}}</td>
        <td>{{ item.ecValue ? item.ecValue/1000 : ''}}</td>
        <td>{{ item.ecMeasure ? item.ecMeasure/1000 : ''}}</td>
        <td>{{ item.phValue ? item.phValue/100 : ''}}</td>
        <td>{{ item.phMeasure ? item.phMeasure/100 : ''}}</td>
        <td>{{ item.waterTempValue ? item.waterTempValue/100 : ''}}</td>
        <td>{{ item.waterTempMeasure ? item.waterTempMeasure/100 : ''}}</td>
        <td>{{ item.humidityValue ? item.humidityValue/100 : ''}}</td>
        <td>{{ item.humidityMeasure ? item.humidityMeasure/100 : ''}}</td>
        <td>{{ item.tempValue ? item.tempValue/100 : ''}}</td>
        <td>{{ item.tempMeasure ? item.tempMeasure/100 : ''}}</td>
        <td>{{ item.co2Value ? item.co2Value : ''}}</td>
        <td>{{ item.co2Measure ? item.co2Measure : ''}}</td>
      </tr>
      </tbody>
    </table>
    <!-- end::TableForExcelDownload -->

  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {mapGetters} from 'vuex';
import TFarmonTableHeader from '@/components/TFarmonTableHeader';
import {
  ACT_GET_STATUS_MEASURE,
  ACT_GET_STATUS_MEASURE_ONE,
  ACT_INSERT_STATUS_MEASURE,
  ACT_UPDATE_STATUS_MEASURE,
  ACT_DELETE_STATUS_MEASURE,
  ACT_GET_MY_CONNECTS,
  ACT_GET_LAST_STATUS_LOG,
  ACT_GET_MY_FARM,
  ACT_GET_FARM_THRESHOLD
} from '@/core/services/variable';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import {todayToDate, getItem, getItems, utcToDate} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';
import * as XLSX from 'xlsx'

export default {
    name: "EnvironmentMeasurementInfomation",
    components: {
        TFarmonTableHeader
    },
    data(){
        return{
          i18n: {
            selectDate: i18n.t('farm.measurement.selectDate'),
            register: i18n.t('farm.measurement.register'),
            edit: i18n.t('farm.measurement.edit'),
            delete: i18n.t('farm.measurement.delete'),
            temperatureControllerMeasured: i18n.t('farm.measurement.temperaturControllerMeasured'),
            humidityControllerMeasured: i18n.t('farm.measurement.humidityControllerMeasured'),
            waterTemperatureControllerMeasured: i18n.t('farm.measurement.waterTemperatureControllerMeasured'),
            co2ControllerMeasured: i18n.t('farm.measurement.co2ControllerMeasured'),
            eCControllerMeasured: i18n.t('farm.measurement.eCControllerMeasured'),
            pHControllerMeasured: i18n.t('farm.measurement.pHControllerMeasured'),
            controllerMeasured: i18n.t('farm.measurement.controllerMeasured'),
            close: i18n.t('farm.measurement.close'),
            controller: i18n.t('farm.measurement.controller'),
            temperature: i18n.t('farm.measurement.temperature'),
            humidity: i18n.t('farm.measurement.humidity'),
            waterTemperature: i18n.t('farm.measurement.waterTemperature'),
            co2: i18n.t('farm.measurement.co2'),
            eC: i18n.t('farm.measurement.eC'),
            pH: i18n.t('farm.measurement.pH'),
            noData: i18n.t('farm.measurement.noData'),
            measure: i18n.t('farm.measurement.measure'),
            measurementInputPage: i18n.t('farm.measurement.measurementInputPage'),
            startYmd: i18n.t('farm.measurement.startYmd'),
            endYmd: i18n.t('farm.measurement.endYmd'),
            am: i18n.t('farm.measurement.am'),
            pm: i18n.t('farm.measurement.pm'),
            excelDownload: i18n.t('farm.measurement.excelDownload'),
            excelDownloadConfirm: i18n.t('farm.measurement.excelDownloadConfirm'),
            excelDownloadComplete: i18n.t('farm.measurement.excelDownloadComplete'),
            excelFileNm: i18n.t('farm.measurement.excelFileNm'),
            invalidConnect: i18n.t('farm.measurement.invalidConnect'),
            getCurrentValue: i18n.t('farm.measurement.getCurrentValue'),
            connectNm: i18n.t('farm.measurement.connectNm'),
            measureDate: i18n.t('farm.measurement.measureDate'),
          },
            isRegister: true,
            systemId: 0,
            statusMeasureNo: 0,
            lastStatusLog: 0,
            tempMeasure: 0,
            tempValue: 0,
            humidityMeasure: 0,
            humidityValue: 0,
            waterTempMeasure: 0,
            waterTempValue: 0,
            co2Measure: 0,
            co2Value: 0,
            ecMeasure: 0,
            ecValue: 0,
            phMeasure: 0,
            phValue: 0,
            connectNm: '',
            registerDt: '',
            amPm: '',
            selectedAmPm: 0,
            amPmOptions: [{value: 0, text: 'AM'},{value: 1, text: 'PM'}],
            connectOptions: [],
            selectedConnect: 0,
            editSystemId: 0,
            temperature: 0,
            humidity: 0,
            co2: 0,
            ph: 0,
            ec: 0,
            waterTemperature: 0,
            currentAmPm: '',
            threshold: {},
            connectsGrowType: {},

            startYmd: null,
            endYmd: utcToDate(new Date(),'yyyy-MM-dd'),

            envMeasureFields: [
                { value: 'measureYmd', text: i18n.t('farm.measurement.measureYmd')},
                { value: 'fieldA', text: i18n.t('farm.measurement.controller')},
                { value: 'fieldB', text: i18n.t('farm.measurement.amPm')},
                { value: 'fieldC', text: i18n.t('farm.measurement.eCControllerMeasured')},
                { value: 'fieldD', text: i18n.t('farm.measurement.pHControllerMeasured')},
                { value: 'fieldE', text: i18n.t('farm.measurement.waterTemperatureControllerMeasured')},
                { value: 'fieldF', text: i18n.t('farm.measurement.humidityControllerMeasured')},
                { value: 'fieldG', text: i18n.t('farm.measurement.temperatureControllerMeasured')},
                { value: 'fieldH', text: i18n.t('farm.measurement.co2ControllerMeasured')},
                { value: 'fieldI', text: '',width: '120px' }
            ],

            envNmList: [
                {value: '1', text: `${i18n.t('dashboard.temperature')}`},
                {value: '2', text: `${i18n.t('dashboard.humidity')}`},
                {value: '3', text: `${i18n.t('dashboard.waterTemperature')}`},
                {value: '4', text: 'CO2'},
                {value: '5', text: 'EC'},
                {value: '6', text: 'pH'},
            ],
            connectFilter: 0,
            connectFilterList: [],
            measureModal: false,
            measures: [],
            totalConnectsLength: 0,
            paging: {
              pageNo: 1,
              pageSize: 10,
              totalCount: 0,
            },
            Excelheader: [i18n.t('farm.measurement.measureYmd'), i18n.t('farm.measurement.controller'), i18n.t('farm.measurement.amPm'),
            i18n.t('farm.measurement.ecVal'),i18n.t('farm.measurement.ecMeasure'),i18n.t('farm.measurement.pHVal'),
            i18n.t('farm.measurement.pHMeasure'),i18n.t('farm.measurement.waterTempVal'),i18n.t('farm.measurement.waterTempMeasure'),
            i18n.t('farm.measurement.humidityVal'),i18n.t('farm.measurement.humidityMeasure'),i18n.t('farm.measurement.tempVal'),
            i18n.t('farm.measurement.tempMeasure'),i18n.t('farm.measurement.co2Val'),i18n.t('farm.measurement.co2Measure')],

            fixedDateRange: {
              activeDateRange: 'week',
              dateRanges: [
                { key: 'day', date: i18n.t('farm.timeseries.day') },
                { key: 'week', date: i18n.t('farm.timeseries.week') },
                { key: 'month', date: i18n.t('farm.timeseries.month') }
              ]
            },
            locale: 'ko',
        }
    },
    created(){
      this.getThreshold()
      this.getconnects()
      this.changeFixedDate(7)
      this.getMeasureItems()
      if(Object.keys(i18n._localeChainCache)[0] == 'en'){
        this.locale = 'en'
      }
      this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('farm.measurement.farmInformation'), icon: 'fas fa-clipboard' }, { title: i18n.t('farm.measurement.EnvManagement') }]);
      window.scrollTo(0,0);
      let today = new Date()
      if(today.getHours() < 12){
        this.currentAmPm = 'AM'
      }else{
        this.currentAmPm = 'PM'
      }
    },
    computed:{
      envMeasureItems(){
          const measureItems = []

          this.measures.forEach((connect) => {
            measureItems.push({
              'fieldA' : connect.connectNm,
              'fieldB' : connect.amPm == 'AM'? `${this.i18n.am}`: `${this.i18n.pm}`,
              'fieldC' : `${connect.ecValue == null ? "-" : connect.ecValue/1000} / ${connect.ecMeasure == null ? "-" : connect.ecMeasure/1000}`,
              'fieldD' : `${connect.phValue == null ? "-" : connect.phValue/100} / ${connect.phMeasure == null ? "-" : connect.phMeasure/100}`,
              'fieldE' : `${connect.waterTempValue == null ? "-" : connect.waterTempValue/100} / ${connect.waterTempMeasure == null ? "-" : connect.waterTempMeasure/100}`,
              'fieldF' : `${connect.humidityValue == null ? "-" : connect.humidityValue/100} / ${connect.humidityMeasure == null ? "-" : connect.humidityMeasure/100}`,
              'fieldG' : `${connect.tempValue == null ? "-" : connect.tempValue/100} / ${connect.tempMeasure == null ? "-" : connect.tempMeasure/100}`,
              'fieldH' : `${connect.co2Value == null ? "-" : connect.co2Value/1} / ${connect.co2Measure == null ? "-" : connect.co2Measure/1}`,
              'tempMeasure': connect.tempMeasure == null ? null : connect.tempMeasure/100,
              'tempValue': connect.tempValue == null ? null : connect.tempValue/100,
              'humidityMeasure': connect.humidityMeasure == null ? null : connect.humidityMeasure/100,
              'humidityValue': connect.humidityValue == null ? null : connect.humidityValue/100,
              'waterTempMeasure': connect.waterTempMeasure == null ? null : connect.waterTempMeasure/100,
              'waterTempValue': connect.waterTempValue == null ? null : connect.waterTempValue/100,
              'co2Measure': connect.co2Measure == null ? null : connect.co2Measure/1,
              'co2Value': connect.co2Value == null ? null : connect.co2Value/1,
              'ecMeasure': connect.ecMeasure == null ? null : connect.ecMeasure/1000,
              'ecValue': connect.ecValue == null ? null : connect.ecValue/1000,
              'phMeasure': connect.phMeasure == null ? null : connect.phMeasure/100,
              'phValue': connect.phValue == null ? null : connect.phValue/100,
              'tempMeasureCheck': connect.tempMeasure === null ? false : connect.tempMeasure/100 < this.threshold.tempDayLimitlow || connect.tempMeasure/100 > this.threshold.tempDayLimithigh,
              'tempValueCheck': connect.tempValue === null ? false : connect.tempValue/100 < this.threshold.tempDayLimitlow || connect.tempValue/100 > this.threshold.tempDayLimithigh,
              'humidityMeasureCheck': connect.humidityMeasure === null ? false : connect.humidityMeasure/100 < this.threshold.humidityDayLimitlow || connect.humidityMeasure/100 > this.threshold.humidityDayLimithigh,
              'humidityValueCheck': connect.humidityValue === null ? false : connect.humidityValue/100 < this.threshold.humidityDayLimitlow || connect.humidityValue/100 > this.threshold.humidityDayLimithigh,
              'co2MeasureCheck': connect.co2Measure === null ? false : connect.co2Measure < this.threshold.co2DayLimitlow || connect.co2Measure > this.threshold.co2DayLimithigh,
              'co2ValueCheck': connect.co2Value === null ? false : connect.co2Value < this.threshold.co2DayLimitlow || connect.co2Value > this.threshold.co2DayLimithigh,
              'ecMeasureCheck': connect.ecMeasure === null ? false
              : this.connectsGrowType[connect.systemId] == '육묘'
              ? connect.ecMeasure/1000 < this.threshold.seedEcLimitlow || connect.ecMeasure/1000 > this.threshold.seedEcLimithigh
              : this.connectsGrowType[connect.systemId] == '이식'
              ? connect.ecMeasure/1000 < this.threshold.transplantEcLimitlow || connect.ecMeasure/1000 > this.threshold.transplantEcLimithigh
              : connect.ecMeasure/1000 < this.threshold.ecLimitlow || connect.ecMeasure/1000 > this.threshold.ecLimithigh,
              'ecValueCheck': connect.ecValue === null ? false
              : this.connectsGrowType[connect.systemId] == '육묘'
              ? connect.ecValue/1000 < this.threshold.seedEcLimitlow || connect.ecValue/1000 > this.threshold.seedEcLimithigh
              : this.connectsGrowType[connect.systemId] == '이식'
              ? connect.ecValue/1000 < this.threshold.transplantEcLimitlow || connect.ecValue/1000 > this.threshold.transplantEcLimithigh
              : connect.ecValue/1000 < this.threshold.ecLimitlow || connect.ecValue/1000 > this.threshold.ecLimithigh,
              'phMeasureCheck': connect.phMeasure === null ? false : connect.phMeasure/100 < this.threshold.phLimitlow || connect.phMeasure/100 > this.threshold.phLimithigh,
              'phValueCheck': connect.phValue === null ? false : connect.phValue/100 < this.threshold.phLimitlow || connect.phValue/100 > this.threshold.phLimithigh,
              'amPm' : connect.amPm,
              'statusMeasureNo' : connect.statusMeasureNo,
              'systemId' : connect.systemId,
              'measureYmd': connect.measureYmd,
            })
          })

          return measureItems.slice((this.paging.pageNo-1)*10,(this.paging.pageNo-1)*10 + 10);
      },
      totalPage() {
        if(this.paging.totalCount != 0){
          return Math.ceil(this.paging.totalCount / this.paging.pageSize);
        }
        return 0;
      },
      ...mapGetters(["isMobile"]),
    },
    watch: {
      '$route' (to) {
        if(to.query.page){
          this.paging.pageNo = to.query.page;
        }else {
          this.paging.pageNo = 1;
        }
      },
      selectedConnect(){
        this.getLastStatusLog()
      },
      connectFilter(){
        this.getLastStatusLog()
      },
      tempMeasure(val){if(val>100000 || val<-100000) this.tempMeasure = null},
      tempValue(val){if(val>100000 || val<-100000) this.tempValue = null},
      humidityMeasure(val){if(val>100000 || val<-100000) this.humidityMeasure = null},
      humidityValue(val){if(val>100000 || val<-100000) this.humidityValue = null},
      waterTempMeasure(val){if(val>100000 || val<-100000) this.waterTempMeasure = null},
      waterTempValue(val){if(val>100000 || val<-100000) this.waterTempValue = null},
      co2Measure(val){if(val>100000 || val<-100000) this.co2Measure = null},
      co2Value(val){if(val>100000 || val<-100000) this.co2Value = null},
      ecMeasure(val){if(val>100000 || val<-100000) this.ecMeasure = null},
      ecValue(val){if(val>100000 || val<-100000) this.ecValue = null},
      phMeasure(val){if(val>100000 || val<-100000) this.phMeasure = null},
      phValue(val){if(val>100000 || val<-100000) this.phValue = null}
    },
    methods: {
      getThreshold(){
        this.$store.dispatch(ACT_GET_MY_FARM).then(resp => {
          const fno = getItem(resp).fno
          this.$store.dispatch(ACT_GET_FARM_THRESHOLD, fno).then(resp =>{
            this.threshold = getItem(resp)
          })
        })
      },
      onFixedDateRangeChanged(dateRange) {
        if (dateRange === this.fixedDateRange.dateRanges[0].key) {
          this.changeFixedDate(1)
        } else if (dateRange === this.fixedDateRange.dateRanges[1].key) {
          this.changeFixedDate(7)
        } else if (dateRange === this.fixedDateRange.dateRanges[2].key) {
          this.changeFixedDate(30)
        }
        this.fixedDateRange.activeDateRange = dateRange
      },
      changeFixedDate(day) {
        let currentDate = new Date();
        const startDate = new Date(currentDate.setDate(currentDate.getDate() - day ));
        const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
        this.startYmd = startDateUTC.toISOString().slice(0, 10)
        this.endYmd = utcToDate(new Date(), 'yyyy-MM-dd')
      },
      onDownloadExcel(){
        const yesCallback = () =>{
          if(this.measures.length == 0){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.ii8n.noData, color: 'danger'});
            return
          }

          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.excelDownloadComplete, color: 'success'}).then(() =>{
            const workSheet = XLSX.utils.table_to_sheet(document.getElementById('excel_download_table_env'), {dateNF:'yyyy/mm/dd hh:mm:ss;@',cellDates:true, raw: true})

            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, `sheet1`);
            XLSX.writeFile(workBook, `[${this.startYmd==null? '': this.startYmd}~${this.endYmd==null? '': this.endYmd}] ${this.$store.state.farm.farmItem.farmNm} ${this.i18n.excelFileNm}`);
          })
        }
        this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
          info: {title: this.i18n.excelDownloadConfirm},
          callback: yesCallback
        })
      },
      checkValue(val1, val2){
        if((val1*2 < val2 || val2*2 < val1) && (val1 != null && val2 !=null) && (val1 != 0 && val2 !=0)){
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.measurement.tooMuchValueDifferences'), color: 'warning'})
        }
      },
      getMeasureItems(){
        let params = {startYmd: this.startYmd, endYmd: this.endYmd, systemId: this.connectFilter}
        this.$store.dispatch(ACT_GET_STATUS_MEASURE, params).then(resp =>{
          this.measures = getItems(resp)
          this.measures = this.measures.sort((a,b)=> {
            return a.measureYmd > b.measureYmd ? -1 : a.measureYmd < b.measureYmd ? 1 : a.amPm > b.amPm ? -1 : a.amPm < b.amPm ? 1 : 0
          })

          this.paging.totalCount = this.measures.length
        })
      },
      getconnects(){
        this.$store.dispatch(ACT_GET_MY_CONNECTS).then(respC =>{
          this.connectOptions = []
          this.selectedConnect = 0
          this.connectFilterList = [{value:0, text: 'All'}]
          this.connectFilter = 0

          if(getItem(respC) !== undefined){
             getItem(respC).farmConnects.forEach(c => {
              this.connectsGrowType[c.systemId] = c.growType
              this.connectFilterList.push({value: c.systemId, text: c.connectNm})
              this.connectOptions.push({value: c.systemId, text: c.connectNm})
            })
            this.selectedConnect = this.connectOptions[0].value
          }else{
            this.connectOptions.push({value: 0, text: '-'})
          }
        })
      },
      setValue(key){
        if(key === 'co2Value') this[key] = this.lastStatusLog[key]
        else if(key === 'tempValue' || key === 'humidityValue' || key === 'phValue' || key === 'waterTempValue') this[key] = this.lastStatusLog[key]/100
        else if(key === 'ecValue') this[key] = this.lastStatusLog[key]/1000
        else if(key === 'all') {
          this['co2Value'] = this.lastStatusLog['co2Value']
          this['tempValue'] = this.lastStatusLog['tempValue']/100
          this['humidityValue'] = this.lastStatusLog['humidityValue']/100
          this['phValue'] = this.lastStatusLog['phValue']/100
          this['waterTempValue'] = this.lastStatusLog['waterTempValue']/100
          this['ecValue'] = this.lastStatusLog['ecValue']/1000
        }
      },
      setModalDefault() {
        this.selectedConnect = this.connectOptions[0].value
        this.editSystemId = 0
        this.registerDt = todayToDate('yyyy-MM-dd')
        this.selectedAmPm = new Date().getHours() < 12 ? 0 : 1
        this.tempMeasure = null
        this.tempValue = null
        this.humidityMeasure = null
        this.humidityValue = null
        this.waterTempMeasure = null
        this.waterTempValue = null
        this.co2Measure = null
        this.co2Value = null
        this.ecMeasure = null
        this.ecValue = null
        this.phMeasure = null
        this.phValue = null
      },
      setModalItem(item){
        this.statusMeasureNo = item.statusMeasureNo
        this.selectedConnect = item.systemId
        this.editSystemId = item.systemId
        this.registerDt = item.measureYmd
        this.selectedAmPm = item.amPm === 'AM' ? 0 : 1
        this.tempMeasure = item.tempMeasure
        this.tempValue = item.tempValue
        this.humidityMeasure = item.humidityMeasure
        this.humidityValue = item.humidityValue
        this.waterTempMeasure = item.waterTempMeasure
        this.waterTempValue = item.waterTempValue
        this.co2Measure = item.co2Measure
        this.co2Value = item.co2Value
        this.ecMeasure = item.ecMeasure
        this.ecValue = item.ecValue
        this.phMeasure = item.phMeasure
        this.phValue = item.phValue
      },
      showMeasureModal(item){
        this.measureModal = true
        if(item === undefined){
          this.isRegister = true
          this.setModalDefault()
        }else{
          this.isRegister = false
          this.setModalItem(item)
        }

        this.getLastStatusLog()
      },
      getLastStatusLog() {
          this.$store.dispatch(ACT_GET_LAST_STATUS_LOG, this.selectedConnect).then(logs =>{
            this.lastStatusLog = getItem(logs)
          })
      },
      saveMeasureModal(){
        if(this.invalidCheck()) return
        let params = {
              systemId: this.selectedConnect,
              measureYmd: this.registerDt,
              amPm: this.selectedAmPm === 0 ? 'AM' : 'PM',
              ecValue: this.ecValue == null || this.ecValue == ''  ? null : (Number(this.ecValue)*1000).toFixed(0),
              ecMeasure: this.ecMeasure == null || this.ecMeasure == '' ? null : (Number(this.ecMeasure)*1000).toFixed(0),
              phValue: this.phValue == null || this.phValue == '' ? null : (Number(this.phValue)*100).toFixed(0),
              phMeasure: this.phMeasure == null || this.phMeasure == '' ? null : (Number(this.phMeasure)*100).toFixed(0),
              co2Value: this.co2Value == null || this.co2Value == '' ? null : Number(this.co2Value),
              co2Measure: this.co2Measure == null || this.co2Measure == '' ? null : Number(this.co2Measure),
              tempValue: this.tempValue == null || this.tempValue == '' ? null : (Number(this.tempValue)*100).toFixed(0),
              tempMeasure: this.tempMeasure == null || this.tempMeasure == '' ? null : (Number(this.tempMeasure)*100).toFixed(0),
              waterTempValue: this.waterTempValue == null || this.waterTempValue == '' ? null : (Number(this.waterTempValue)*100).toFixed(0),
              waterTempMeasure: this.waterTempMeasure == null || this.waterTempMeasure == '' ? null : (Number(this.waterTempMeasure)*100).toFixed(0),
              humidityValue: this.humidityValue == null || this.humidityValue == '' ? null : (Number(this.humidityValue)*100).toFixed(0),
              humidityMeasure: this.humidityMeasure == null || this.humidityMeasure == '' ? null : (Number(this.humidityMeasure)*100).toFixed(0)
        }
        let checkParams = {systemId: this.selectedConnect, amPm: this.selectedAmPm === 0 ? 'AM' : 'PM', measureYmd: this.registerDt}

        if(this.isRegister){
          this.$store.dispatch(ACT_GET_STATUS_MEASURE_ONE, checkParams).then(resp =>{
            if(getItem(resp) !== undefined && getItem(resp).systemId !== this.editSystemId){
              const yesCallback = () => {
                this.$store.dispatch(ACT_INSERT_STATUS_MEASURE, params).then(() =>{
                  this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.measurement.measureRegistered'), color: 'success'})
                  this.measureModal = false
                  this.getMeasureItems()
                })
              }

              this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
                info: {title: i18n.t('farm.measurement.alreadyEntered')},
                callback: yesCallback,
              })
            }else{
              this.$store.dispatch(ACT_INSERT_STATUS_MEASURE, params).then(() =>{
                this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.measurement.measureRegistered'), color: 'success'})
                this.measureModal = false
                this.getMeasureItems()
              })
            }
          })
        }else {
          params.statusMeasureNo = this.statusMeasureNo
          this.$store.dispatch(ACT_GET_STATUS_MEASURE_ONE, checkParams).then(resp =>{
            if(getItem(resp) !== undefined && getItem(resp).systemId !== this.editSystemId){
              const yesCallback = () => {
                this.$store.dispatch(ACT_UPDATE_STATUS_MEASURE, params).then(() =>{
                  this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.measurement.measureRegistered'), color: 'success'})
                  this.measureModal = false
                  this.getMeasureItems()
                })
              }

              this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
                info: {title: i18n.t('farm.measurement.alreadyEntered')},
                callback: yesCallback,
              })
            }else{
              this.$store.dispatch(ACT_UPDATE_STATUS_MEASURE, params).then(() =>{
                this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.measurement.measuredValuechanged'), color: 'success'})
                this.measureModal = false
                this.getMeasureItems()
              })
            }
          })
        }
      },
      deleteMeasure(item){
        const yesCallback = () => {
          this.$store.dispatch(ACT_DELETE_STATUS_MEASURE, item.statusMeasureNo).then(()=>{
              this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.measurement.measuredValueDeleted'), color: 'success'}).then(()=>{
              this.getMeasureItems()
              })
          })
        }

        this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
          info: {title: i18n.t('farm.measurement.measuredControllerValaueDeleted')},
          callback: yesCallback,
        });
      },
      invalidCheck(){
        let invalid = false, message = ''
        if(this.selectedConnect === 0){
          invalid = true
          message = this.i18n.invalidConnect
        }
        if(invalid === true) this.$store.dispatch(ACT_ADD_ALERT, {message: message, color: 'warning'})
        return invalid
      },
      linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
      },
    },
}
</script>