<template>
  <b-modal
      v-model="farmModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
  >
    <div class="popup-smartfarm">
      <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 align-items-center">
          <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            {{i18n.smartFarmDetail}}
          </span>
          </h3>
          <div class="card-toolbar toolbar-symbol">
            <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="farmModal = false">
              <i class="la la-close font-size-h5"></i>
            </b-button>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body py-0">
          <!--begin::Info-->
          <div class="smartfarm-info px-lg-6 px-0 mb-4">
            <ul class="text-group row list-unstyled mb-0">

              <li class="text-item col-12">
                <div class="row">
                  <div class="title col-12 col-lg-2 mb-lg-3">
                    <span class="font-size-lg font-weight-bold"> {{i18n.companyNm}} </span>
                  </div>
                  <div class="description col-12 col-lg-10 mb-3 mb-lg-3">
                    <span class="text text-truncate">{{ companyNm }}</span>
                  </div>
                </div>
              </li>

              <li v-for="(basic, idx) in basicItems" :key="idx" class="text-item col-6">
                <div class="row">
                  <div class="title col-12 col-lg-4 mb-lg-3">
                    <span class="font-size-lg font-weight-bold">{{ basic.title }}</span>
                  </div>
                  <div class="description col-12 col-lg-8 mb-3 mb-lg-3">
                    <span class="text text-truncate">{{ basic.desc }}</span>
                  </div>
                </div>
              </li>

            </ul>
          </div>
          <!--end::Info-->
          <!--begin::Tabs-->
          <div class="smartfarm-tabs">
            <b-tabs justified>
              <b-tab active>
                <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Archive.svg" />
                </span>
                  <span class="nav-text font-weight-bold font-size-sm">{{i18n.basicInfoA}}</span>
                </template>
                <Smartfarm1 :info-a="infoA"></Smartfarm1>
              </b-tab>
              <b-tab>
                <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Archive.svg" />
                </span>
                  <span class="nav-text font-weight-bold font-size-sm">{{i18n.basicInfoB}}</span>
                </template>
                <Smartfarm2 :info-b="infoB"></Smartfarm2>
              </b-tab>
              <b-tab>
                <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Umbrella.svg" />
                </span>
                  <span class="nav-text font-weight-bold font-size-sm">{{i18n.controllerInfo}}</span>
                </template>
                <Smartfarm3 :connect-info="connectInfo"></Smartfarm3>
              </b-tab>
              <b-tab>
                <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Wood2.svg" />
                </span>
                  <span class="nav-text font-weight-bold font-size-sm">{{i18n.cropInfo}}</span>
                </template>
                <Smartfarm4 :crop-info="cropInfo"></Smartfarm4>
              </b-tab>
              <b-tab>
                <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Electric/Outlet.svg" />
                </span>
                  <span class="nav-text font-weight-bold font-size-sm">{{i18n.machineInfo}}</span>
                </template>
                <Smartfarm5 :machine-info="machineInfo"></Smartfarm5>
              </b-tab>
            </b-tabs>
          </div>
          <!--end::Tabs-->
        </div>
        <!--end::Body-->
        <!--begin::Footer-->
        <div class="card-footer">
          <b-button variant="secondary" size="lg" class="ml-3" @click="farmModal = false">
            <span class="">{{i18n.close}}</span>
          </b-button>
        </div>
        <!--end::Footer-->
      </div>
    </div>

  </b-modal>
</template>

<script>
import Smartfarm1 from '@/view/content/popup/Smartfarm1.vue';
import Smartfarm2 from '@/view/content/popup/Smartfarm2.vue';
import Smartfarm3 from '@/view/content/popup/Smartfarm3.vue';
import Smartfarm4 from '@/view/content/popup/Smartfarm4.vue';
import Smartfarm5 from '@/view/content/popup/Smartfarm5.vue';
import i18n from '@/core/plugins/vue-i18n.js';
import {ACT_GET_MY_FARM, MUT_SET_FARM_DETAIL} from '@/core/services/variable';
import {collectionsCheck, getItem, getValueWithDefault, lengthCheck} from '@/core/services/funcs';
import {mapGetters} from 'vuex';

export default {
  name: "FarmModal",
  components: {
    Smartfarm1,
    Smartfarm2,
    Smartfarm3,
    Smartfarm4,
    Smartfarm5
  },
  props: {
    target: Number
  },
  watch: {
    target(){
      this.farmModal = true;
      if(this.farmDetail == null){
        this.$store.dispatch(ACT_GET_MY_FARM).then(resp => {
          if(lengthCheck(resp)) {
            const item = getItem(resp);
            this.$store.commit(MUT_SET_FARM_DETAIL, item);
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters(["farmDetail"]),
    companyNm() {
      if(this.farmDetail){
        return this.farmDetail.companyNm + ' ' + (this.farmDetail.companyCd ? '(' + this.farmDetail.companyCd + ')' : '')
      }
      return '-';
    },
    basicItems() {
      if(this.farmDetail){
        return [
          { title: i18n.t('farm.farmDetail.farmName'), desc: this.farmDetail.farmNm},
          { title: i18n.t('farm.farmDetail.farmCode'), desc: getValueWithDefault(this.farmDetail.farmCd), required: ''},
          { title: i18n.t('farm.farmDetail.operatingState'), desc: this.farmDetail.operationSt, status: (this.farmDetail.operationStDcd === '1020001' ? 'is-active' : '')},
          { title: i18n.t('farm.farmDetail.cultivateType'), desc: getValueWithDefault(this.farmDetail.cultivateType)}
        ]
      }
      return [];
    },
    infoA(){
      if(this.farmDetail){
        return {
          farmAddr: this.farmDetail.farmAddr,
          farmAddrDetail: this.farmDetail.farmAddrDetail,
          sidoGb: this.farmDetail.sidoGb,

          farmAvgProductionPDay: this.farmDetail.farmAvgProductionPDay,
          farmAvgProductionPWeek: this.farmDetail.farmAvgProductionPWeek,
          waterAvgUsage: this.farmDetail.waterAvgUsage,
          electricAvgUsage: this.farmDetail.electricAvgUsage,
          farmAvgProductionPMonth: this.farmDetail.farmAvgProductionPMonth,
          co2AvgUsage: this.farmDetail.co2AvgUsage,

          farmFiles: this.farmDetail.farmFiles,

          farmManagerName: this.farmDetail.farmManagerName,
          farmManagerPhoneNo: this.farmDetail.farmManagerPhoneNo,
          farmManagerEmail: this.farmDetail.farmManagerEmail
        };
      }
      return null;
    },
    infoB(){
      if(this.farmDetail){
        return {
          farmLocation: this.farmDetail.farmLocation,
          farmLocationGb: this.farmDetail.farmLocationGb,
          farmArea: this.farmDetail.farmArea,
          farmCultivateArea: this.farmDetail.farmCultivateArea,
          envCtrlCount: this.farmDetail.envCtrlCount,
          nutCtrlCount: this.farmDetail.nutCtrlCount,

          layerCount: this.farmDetail.layerCount,
          rowCount: this.farmDetail.rowCount,
          rackType1: this.farmDetail.rackType1,
          rackType2: this.farmDetail.rackType2,
          rackType3: this.farmDetail.rackType3,
          rackType4: this.farmDetail.rackType4,
          rackType5: this.farmDetail.rackType5,
          bedStandards: this.farmDetail.bedStandards,
          bedCount: this.farmDetail.bedCount,
          rackCount: this.farmDetail.rackCount,
          seedRackCount: this.farmDetail.seedRackCount,
          transplantRackCount: this.farmDetail.transplantRackCount,
          lastfeedRackCount: this.farmDetail.lastfeedRackCount,
          potCount: this.farmDetail.potCount,
        };
      }
      return null;
    },
    connectInfo(){
      if(this.farmDetail){
        return this.farmDetail.farmConnects;
      }
      return [];
    },
    cropInfo(){
      if(this.farmDetail && collectionsCheck(this.farmDetail.farmCultivateCrops, 'cultivateCropSeq')){
        return this.farmDetail.farmCultivateCrops;
      }
      return [];
    },
    machineInfo(){
      if(this.farmDetail && collectionsCheck(this.farmDetail.farmMachines, 'seq')){
        return this.farmDetail.farmMachines;
      }
      return [];
    }
  },
  data() {
    return {
      i18n: {
        companyNm: i18n.t('farm.farmDetail.companyNm'),
        smartFarmDetail: i18n.t('farm.farmDetail.smartFarmDetail'),
        farmName: i18n.t('farm.farmDetail.farmName'),
        farmCode: i18n.t('farm.farmDetail.farmCode'),
        operatingState: i18n.t('farm.farmDetail.operatingState'),
        cultivateType: i18n.t('farm.farmDetail.cultivateType'),
        basicInfoA: i18n.t('farm.basicInfoA'),
        basicInfoB: i18n.t('farm.basicInfoB'),
        controllerInfo: i18n.t('farm.controllerInfo'),
        cropInfo: i18n.t('farm.cropInfo'),
        machineInfo: i18n.t('farm.machineInfo'),
        close: i18n.t('cultivationEnvControlSetting.close')
      },
      farmModal: false,
    }
  }
};
</script>