<template>
  <div class="card-title top" v-bind:class="{'pb-0': noBlank}">
    <h3 class="card-label">
      <div class="d-flex align-items-center">
        <span class="title font-weight-bold text-dark mr-3">{{ farmNm }}</span>
        <b-button size="sm" variant="dark" class="btn-xs" @click="viewFarmDetail">
          <span class="text font-size-sm font-weight-normal">{{i18n.detail}}</span>
        </b-button>
      </div>
      <!--begin::metadata-->
      <div class="metadata d-flex align-items-center">
        <!--begin::metadata-item-->
        <div v-if="totalCount" class="metadata-item d-flex align-items-center mt-3 mr-3">
          <span class="metadata-icon metadata-icon-list font-size-sm mr-1">
            <span class="metadata-svg svg-icon svg-icon-white">
              <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../srchttps://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons\Text\Article.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <rect fill="#000000" x="4" y="5" width="16" height="3" rx="1.5"/>
                    <path d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L12.5,10 C13.3284271,10 14,10.6715729 14,11.5 C14,12.3284271 13.3284271,13 12.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z" fill="#000000" opacity="1"/>
                </g>
            </svg><!--end::Svg Icon-->
            </span>
          </span>
          <span class="text font-weight-bold font-size-sm">{{i18n.total}} {{ totalCount }} {{i18n.units}}</span>
        </div>

        <div class="metadata-item d-flex align-items-center mt-3">
          <span class="metadata-svg svg-icon svg-icon-light-dark mr-1">
            <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Call.svg" />
          </span>
          <span class="text font-weight-bold font-size-sm text-muted">{{ farmManagerPhoneNo }}</span>
        </div>
        <!--end::metadata-item-->
      </div>
      <!--end::metadata-->
    </h3>

    <farm-modal
        :target="target"
    ></farm-modal>


  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import FarmModal from '@/components/modal/FarmModal';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: 'TFarmonTableHeader',
  components: {
    FarmModal
  },
  props: {
    totalCount: Number,
    noBlank: Boolean
  },
  computed: {
    ...mapGetters(["farmNm", "farmManagerPhoneNo"])
  },
  data (){
    return {
      target: 0,
      i18n:{
        detail:i18n.t('farm.cultivationCropMangement.detail'),
        units: i18n.t('farm.weeklyReport.units'),
        total: i18n.t('farm.weeklyReport.total'),
      }
    }
  },
  methods: {
    viewFarmDetail() {
      this.target++;
    }
  }
};
</script>